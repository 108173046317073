import React from "react"
import { AnimatedLink } from "@components/shared"

import { product__tags, product__tag } from "../styles/product.module.scss"

const Tags = ({ productTags }) => {
  if (productTags.length !== 0) {
    return (
      <div className={product__tags}>
        {productTags.map(({ name, slug }, k) => {
          return (
            <div className={product__tag} key={k}>
              <AnimatedLink to={`/tags/${slug}/`}>#{name}</AnimatedLink>
            </div>
          )
        })}
      </div>
    )
  }
  return null
}

export default Tags
