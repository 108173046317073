import React, { useRef, useEffect } from "react"
import is from "is_js"

import { PhaseInput } from "@components/shared"

const validateValue = (_type, value) => {
  if (String(value).length < 3) {
    return [true, "Valid"]
  }

  switch (_type) {
    case "email":
      return [is.email(value), "Niepoprawny email"]
    case "text":
      return [is.string(value) && value.length > 2, "Za krótki tekst"]
    case "number":
      return [
        is.number(Number(value)) && String(value).length >= 9,
        "Numer jest nieprawidłowy",
      ]
    default:
      return [true, "Valid"]
  }
}

const TextInput = ({
  onChange = console.log,
  onEnter = false,
  placeholder = "placeholder text",
  value = "value",
  type = "text",
  name = "",
  readOnly = false,
  disabled = false,
  label = false,
  smallText = false,
  validate = "text",
  autofocus = false,
  className = "",
}) => {
  const ref = useRef(null)

  useEffect(() => {
    if (autofocus) {
      ref.current.focus()
    }
  }, [])

  const [isValid, message] = validateValue(validate, value)

  return (
    <div className={className}>
      {label && <label>{label}</label>}
      <input
        type={type}
        placeholder={placeholder}
        name={name}
        ref={ref}
        onChange={onChange}
        readOnly={readOnly}
        disabled={disabled}
        onKeyPress={(ev) => {
          if (ev.key === "Enter") {
            onEnter && onEnter(ev.target.value)
          }
        }}
        value={value}
      />
      {smallText && <small>{smallText}</small>}
      {!isValid && <small>{message}</small>}
    </div>
  )
}

export default TextInput
