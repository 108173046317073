import React, { useContext, useEffect } from "react"
import { useLocation } from "@reach/router"

import { Header, Head, Footer } from "@components/layout"
// import Main from './Main'
import SideLayout from "./SideLayout"

import { main } from "./layout.module.scss"

// import {
//   Footer,
//   Head,
//   Header,
//   Loader,
//   DelayedRouter,
//   CookiesModal,
// } from "@components/layout"

const Layout = ({ children, siteMetaData, seo, imBusy, translationUrl }) => {
  const location = useLocation()
  let defaultTranslation = {
    pl:"/pl",
    en:"/en"
  }

  if(translationUrl){
    defaultTranslation = translationUrl
  }
  // useEffect(()=>{
  // 	window.scrollTo(0,1)
  // 	// window.scrollTo(0,0)
  // },[])

  // return (
  //   <React.Fragment>
  //     <Loader show={imBusy} />
  //     <CookiesModal />
  //     <DelayedRouter>
  //       <Header className={app__header} />
  //       <Main>{children}</Main>
  //       <Footer />
  //     </DelayedRouter>
  //   </React.Fragment>
  // )

  return (
    <div>
      <Head seo={seo} siteMetaData={siteMetaData} location={location} />
      <Header translationUrl={defaultTranslation}/>
      <SideLayout />
      <main className={main}>{children}</main>
      <Footer />
    </div>
  )
}

export default Layout
