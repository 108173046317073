import React from "react"

import { AnimatedButton } from "@components/shared"

import { AppCtx, useContextState } from "@components/contexted"
import { summary__stats, sum__wrapper } from "../styles/checkout.module.scss"

import summaryIcon from "../img/wallet.png"

const OrderSummary = ({ cart, onInitPayment }) => {
  const { imBusy } = useContextState(AppCtx, "imBusy")

  const { orderInApi } = cart
  console.log("orderInApi", orderInApi)
  const {
    coupon_lines,
    discount_total,
    total,
    shipping_lines,
    currency,
  } = orderInApi

  // const cart = useContextState(CartCtx, "*")

  return (
    <div className={summary__stats}>
      <h5>
        ID zamówienia: <strong>{orderInApi.id}</strong>
      </h5>
      {coupon_lines.length !== 0 && (
        <React.Fragment>
          <h5>
            Użyty kupon: <strong>{coupon_lines[0].code}</strong>
          </h5>
          <h5>
            Wysokość rabatu:{" "}
            <strong>
              {discount_total}
              {currency}
            </strong>
          </h5>
        </React.Fragment>
      )}

      <h5>
        Koszt przesyłki:{" "}
        <strong>
          {shipping_lines[0].method_title}
          {" - "}
          {shipping_lines[0].total}
          {currency}
        </strong>
      </h5>
      <h5>
        Do zapłaty:{" "}
        <strong>
          {total}
          {currency}
        </strong>
      </h5>

      <div className={sum__wrapper}>
        <AnimatedButton onClick={onInitPayment}>
          {imBusy ? "Procesowanie" : "Przejdź do płatności"}
          <i className="icofont-double-right"></i>
        </AnimatedButton>
      </div>
      <img
        src={summaryIcon}
        alt="wallet"
        style={{ maxWidth: 350, opacity: 0.3, position: "absolute" }}
      />
    </div>
  )
}

export default OrderSummary
