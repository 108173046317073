import React from "react"

import {
  stepper__wrapper,
  stepper__view,
  stepper__buttons,
  stepper__button__wrapper,
  stepper__button,
  stepper__button__active,
} from "./stepper.module.scss"

import { Slider, AnimatedButton, AnimatedSection } from "@components/shared"

class Stepper extends React.Component {
  buttons = React.createRef()
  state = {
    actualIndex: 0,
  }

  setIndex = (idx, withScroll) => {
    this.setState(
      {
        actualIndex: idx,
      },
      () => {
        if (withScroll) {
          window.scrollTo({
            top: this.buttons.current.offsetTop - this.buttons.current.height,
            left: 0,
          })
        }
      }
    )
  }

  render() {
    const { children, withScroll = false } = this.props
    if ([undefined, null].includes(children)) {
      return "No children"
    }

    const buttons = React.Children.map(children, (child, key) => {
      if (child) {
        return (
          <AnimatedButton
            onClick={() => {
              this.setIndex(key, withScroll)
            }}
            disabled={child.props.disabled}
            className={[
              stepper__button__wrapper,
              this.state.actualIndex !== key
                ? stepper__button
                : `${stepper__button} ${stepper__button__active}`,
            ].join(" ")}
          >
            {child.props.title}
          </AnimatedButton>
        )
      }
      return null
    })

    const view = React.Children.map(children, (child) => {
      if (child) {
        return <AnimatedSection>{child}</AnimatedSection>
      } else {
        return null
      }
    })[this.state.actualIndex]

    return (
      <div className={stepper__wrapper} ref={this.buttons}>
        {buttons.length > 1 && (
          <div className={stepper__buttons} title="1">
            {buttons}
          </div>
        )}
        <div className={stepper__view}>{view}</div>
      </div>
    )
  }
}

export default Stepper
