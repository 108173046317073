import React, { useState, useEffect } from "react"
import { truncate } from "lodash"

import { AnimatedLink } from "@components/shared"

import {
  breadcrumbs,
  breadcrumbs__link,
  breadcrumbs__link__active,
  breadcrumbs__span,
} from "./breadcrumbs.module.scss"

interface IBreadcrumbs {
  label: string
  url: string
}

const Breadcrumbs: React.FC<{ elements: IBreadcrumbs[] }> = ({
  elements = [],
}): JSX.Element => {
  let selectedLanguage = "/pl"
  if(typeof window !== "undefined"){
    selectedLanguage = localStorage.getItem("language")
  }
  let labelString = "Główna"
  if(selectedLanguage.replace("/","") === "en"){
    labelString = "Main"
  }

  const [state, setter] = useState<IBreadcrumbs[]>([
    { label: labelString, url: `${selectedLanguage}` },
  ])

  useEffect(() => {
    setter((prevState) => [...prevState, ...elements])
  }, [])

  return (
    <div className={breadcrumbs}>
      {state.map((elm) => (
        <>
          <AnimatedLink
            key={elm.url}
            to={elm.url}
            className={breadcrumbs__link}
            activeClassName={breadcrumbs__link__active}
          >
            <span
              className={breadcrumbs__span}
              dangerouslySetInnerHTML={{
                __html: truncate(elm.label, { length: 60 }),
              }}
            />
          </AnimatedLink>
        </>
      ))}
    </div>
  )
}

export default Breadcrumbs
