import React, { useState, useEffect } from "react"
import { useLocation } from "@reach/router"
// import { AnimatedSection, AnimatedLink } from '@components/shared'
// import { Auth } from '@components/theme'
// import { Cart, CartEmitter } from '..'
// import logoY from '../../../images/logo-yellow.png'

import { app__footer, app__footer__copyFoot } from "./footer.module.scss"

// const withDelay = (fn, delay = 2000) => {
//   const timer = setTimeout(() => {
//     fn()
//     clearTimeout(timer)
//   }, delay)
// }

export const Footer = () => {
  const { pathname } = useLocation()

  return (
    <div className={app__footer}>
      <div className={app__footer__copyFoot}>
        &copy; Copyright 2020 by Localhost Group sp.z.o.o
      </div>
    </div>
  )
}

export default Footer
