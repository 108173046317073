import React from "react"
import { IMenu } from "./types"
import {
  hamburger,
  hamburger__box,
  hamburger__box__inner,
  hamburger__box__inner__active,
} from "./header.module.scss"

const Hamburger: React.FC<IMenu> = ({
  isMenuOpen,
  toggleMenu,
}): JSX.Element => (
  <button
    type="button"
    aria-label="Menu"
    className={hamburger}
    onClick={toggleMenu}
  >
    <span className={hamburger__box}>
      <span
        className={
          isMenuOpen
            ? `${hamburger__box__inner} ${hamburger__box__inner__active}`
            : hamburger__box__inner
        }
      />
    </span>
  </button>
)

export default Hamburger
