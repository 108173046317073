import React, { useEffect } from "react"
import { CustomerCtx, CartCtx, useContextState } from "@components/contexted"
import { GitHubApi } from "@api"
import accepted from "./img/ACCEPTED.png"

import { AnimatedLink } from "@components/shared"

import {
  summary__stats,
  summary__stats__links,
  summary__stats__links__single,
} from "./styles/summary.module.scss"

const PurchaseCompleted = ({ orderId }) => {
  // dodawanie do repo po zakupie

  let { note } = useContextState(CustomerCtx, ["note"])
  let boughtProduct = null
  if (typeof window !== "undefined") {
    boughtProduct = localStorage.getItem("boughtProduct")
  }
  useEffect(() => {
    if (note && boughtProduct) {
      GitHubApi.addCollaborator(note, boughtProduct).then((data) => {
        // console.log(data)
      })
    }
  }, [])

  return (
    <div className={summary__stats}>
      <h3>
        Status: <strong>Płatność zakończona.</strong>
      </h3>
      <h5>W przypadku problemu ze zrealizowaniem zakupów prosimy o kontakt:</h5>
      <div className={summary__stats__links}>
        <div className={summary__stats__links__single}>
          <i class="fab fa-facebook-square"></i>
          <h5>
            <a target="_blank" href="https://www.facebook.com/localhost40310">
              {" "}
              Localhost Group
            </a>
          </h5>
        </div>
        <div className={summary__stats__links__single}>
          <i class="far fa-envelope"></i>
          <h5>
            <a href="mailto:contact@localhost-group.com">
              {" "}
              contact@localhost-group.com
            </a>
          </h5>
        </div>
        <div className={summary__stats__links__single}>
          <i class="fas fa-mobile-alt"></i>
          <h5>
            <a href="tel:+48535000959">(+48) 535 000 959</a>
          </h5>
        </div>
      </div>
      <img src={accepted} alt="payment-accepted" />
    </div>
  )
}
export default PurchaseCompleted
