import React, { useState, useEffect, useCallback, useRef } from "react"
import { useTransition, animated, config } from "react-spring"
import { ResizedImage as Image } from "@components/shared"

import { slider__wrapper, slider__element } from "./slider.module.scss"

const defaultSliderSize = 300

const Slider = ({
  items = [],
  size = [defaultSliderSize, defaultSliderSize],
}) => {
  const [index, set] = useState(0)
  const ref = useRef(null)

  const transitions = useTransition(items[index], (item) => item.slug, {
    from: { opacity: 0, height: 0, top: 1 * size[1], left: 0 },
    enter: { opacity: 1, height: 1 * size[1], top: 0, left: 0 },
    leave: {
      opacity: 0,
      height: 0.2 * size[1],
      top: 0.4 * size[1],
      left: -1 * size[0],
    },
    config: config.gentle,
  })

  const nextSlide = useCallback(() => {
    clearTimeout(ref.current)
    set((state) => (state + 1) % items.length)
  }, [])

  useEffect(() => {
    ref.current = setTimeout(() => {
      set((state) => (state + 1) % items.length)
      clearTimeout(ref.current)
    }, 3000)
    return () => {
      clearTimeout(ref.current)
    }
  }, [index])

  return (
    <a className={slider__wrapper} onClick={nextSlide}>
      {transitions.map(({ item, props, key }) => (
        <animated.div key={key} className={slider__element} style={props}>
          <Image {...item} size={"300w"} />
        </animated.div>
      ))}
    </a>
  )
}

export default Slider
