import React, { useEffect, useContext } from "react"
import { connect } from "react-redux"
import {
  CartCtx,
  AppCtx,
  useActions,
  useContextState,
} from "@components/contexted"
import { Layout } from "@components/layout"

import { ErrorMessage, OrderSummary, initPayment } from "./common"

const BeforePayment = (props) => {
  const cart = useContextState(CartCtx, "*")

  const { loading, loaded } = useActions(AppCtx, ["loading", "loaded"])
  const { onOrderApiError } = useActions(CartCtx, ["onOrderApiError"])

  const { page } = props.pageContext

  useEffect(() => {}, [])

  return (
    <Layout {...page}>
      <div className="checkout">
        <ErrorMessage cart={cart} />
        <OrderSummary
          cart={cart}
          onInitPayment={() => {
            initPayment({
              cart,
              initLoading: loading,
            })
              .then((payment) => {
                console.log('success', payment)
                loaded()
                window.location.href = payment.redirectUri
                let ids = []
                {
                  cart.items.map((el, k) => ids.push(el.product_id))
                }
                localStorage.setItem("boughtProduct", ids)
              })
              .catch(({ data }) => {
                console.log('fail', data)
                onOrderApiError(data)
              })
          }}
        />
      </div>
    </Layout>
  )
}

export default BeforePayment
