import React, { useEffect, useState } from "react"
import { CartCtx, useActions, useContextState } from "@components/contexted"
import { cart__summary, discount__wrapper } from "../cart.module.scss"
import { WPApi } from "@api"

const Summary = ({ sum, unit }) => {
  const [state, setState] = useState({ coupons: [] })
  const { setCoupon } = useActions(CartCtx, ["setCoupon"])
  const { coupon } = useContextState(CartCtx, ["coupon"])
  let selectedLanguage = "/pl"
  if(typeof window !== "undefined"){
    selectedLanguage = localStorage.getItem("language").replace("/","")
  }

  if (state.coupons.length == 0) {
    WPApi.getAllCoupons().then((data) => {
      setState({
        ...state,
        coupons: data,
      })
    })
  }

  let couponAmount = 0
  if (state.coupons) {
    if (coupon) {
      state.coupons.map((el, k) => {
        if (el.code == coupon) {
          couponAmount = el.amount
        }
      })
    }
  }

  const translations = {
    pl:{
      placeholder:"Mam kupon rabatowy",
      sum:"Razem:",
      couponValue:"Wartość kuponu:"
    },
    en:{
      placeholder:"I have a discount coupon",
      sum:"Sum:",
      couponValue:"Coupon amount:"
    }
  }

  return (
    <div>
      <div className={discount__wrapper}>
        <input
          type="text"
          placeholder={translations[selectedLanguage].placeholder}
          onChange={(ev) => {
            setCoupon(ev.target.value)
          }}
          value={coupon}
        />
      </div>
      <div className={cart__summary}>
        <h2>
          {translations[selectedLanguage].sum}{" "}
          <span>
            {sum.val}
            <small>{unit}</small>
          </span>
        </h2>
        {couponAmount != 0 ? (
          <h6>{translations[selectedLanguage].couponValue} {parseInt(couponAmount)} PLN</h6>
        ) : (
          <h6>&nbsp;</h6>
        )}
      </div>
    </div>
  )
}

export default Summary
