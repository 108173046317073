// import React from 'react'
// import { WPApi } from '@api'
// import {
//   TextInput,
//   AnimatedButton,
//   AnimatedLink,
//   AnimatedSection,
// } from '@components/shared'
// import { FlexRow, Parallax } from '@components/layout'
// import { Link } from 'gatsby'
// import is from 'is_js'
// import halfmask from './img/halfmask.svg'
// import {
//   wrapp,
//   news__wrapper,
//   header,
//   header__title,
//   header__subtitle,
//   form,
//   form__input,
//   form__button,
//   form__msg,
//   link__more,
// } from './styles/newsletter.module.scss'

// class NewsletterForm extends React.Component {
//   state = {
//     email: '',
//     msg: '',
//     imBusy: false,
//   }

//   action = () => {
//     if (is.email(this.state.email) === true) {
//       this.setState({
//         imBusy: true,
//       })
//       WPApi.sendMail(this.state.email).then(({ message }) => {
//         this.setState({
//           email: '',
//           msg: message,
//           imBusy: false,
//         })
//       })
//     }
//   }

//   render() {
//     if (this.state.imBusy) {
//       return (
//         <div className={form}>
//           <h6 className={form__msg}>Dodaję Cię do listy mailingowej</h6>
//         </div>
//       )
//     }
//     if (this.state.msg != '') {
//       return (
//         <div className={form}>
//           <h6 className={form__msg}>{this.state.msg}</h6>
//         </div>
//       )
//     }

//     const {
//       title = 'Zapisz się do naszego newslettera i nigdy nie przegap nowym artykułów',
//     } = this.props

//     return (
//       <div className={news__wrapper}>
//         <div className={header}>
//           <h2 className={header__title}>{title}</h2>
//         </div>

//         <form>
//           <TextInput
//             type="email"
//             className={form__input}
//             value={this.state.email}
//             placeholder="Wprowadź adres email"
//             onChange={(ev) => {
//               const { value } = ev.target
//               this.setState({
//                 email: value,
//               })
//             }}
//           />
//         </form>
//       </div>
//     )
//   }
// }

// export const NewsletterSection = () => <NewsletterForm />

// export default NewsletterSection

import React from "react"
import { WPApi } from "@api"
import {
  TextInput,
  AnimatedButton,
  AnimatedLink,
  AnimatedSection,
} from "@components/shared"
import { FlexRow, Parallax } from "@components/layout"
import { Link } from "gatsby"
import is from "is_js"
import { button__primary } from "@styles/button.module.scss"
import halfmask from "./img/halfmask.svg"
import { newsletter } from "./styles/newsletter.module.scss"

class NewsletterForm extends React.Component {
  state = {
    email: "",
    msg: "",
    imBusy: false,
  }

  action = () => {
    if (is.email(this.state.email) === true) {
      this.setState({
        imBusy: true,
      })
      WPApi.sendMail(this.state.email).then(({ message }) => {
        this.setState({
          email: "",
          msg: message,
          imBusy: false,
        })
      })
    }
  }

  render() {
    if (this.state.imBusy) {
      return (
        <div className={form}>
          <h6 className={form__msg}>Dodaję Cię do listy mailingowej</h6>
        </div>
      )
    }
    if (this.state.msg != "") {
      return (
        <div className={form}>
          <h6 className={form__msg}>{this.state.msg}</h6>
        </div>
      )
    }

    const {
      title = "Zapisz się do naszego newslettera i nigdy nie przegap nowych artykułów",
    } = this.props

    return (
      <div className={newsletter}>
        <h2>{title}</h2>
        <form>
          <label htmlFor="email">
            {/* error={errorElements.includes('email') && 'error'} */}
            {/* onInvalid={handleInvalidInput} onChange={handleChange} */}
            <input
              required
              type="text"
              name="email"
              id="email"
              value={this.state.email}
            />
            <span>E-mail</span>
          </label>
          <button className={button__primary} type="submit">
            Zapisz się!
          </button>
        </form>
      </div>
    )
  }
}

export const NewsletterSection = () => <NewsletterForm />

export default NewsletterSection
