import React from "react"
import { AnimatedLink } from "@components/shared"

import { cart__checkout } from "../cart.module.scss"

const ToCheckoutAction = () => {
  let selectedLanguage = "/pl"
  if(typeof window !== "undefined"){
    selectedLanguage = localStorage.getItem("language")
  }
  return (
    <div>
      <AnimatedLink to={`${selectedLanguage}/shop/checkout/`} className={cart__checkout}>
        {/* Do płatności */}
        <i className="icofont-credit-card" />
        <i className="icofont-double-right" />
      </AnimatedLink>
    </div>
  )
}

export default ToCheckoutAction
