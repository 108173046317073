import React from "react"
import { truncate } from "lodash"
import { Link } from "gatsby"
import { ResizedImage as Image } from "@components/shared"
import {
  item,
  item__title,
  item__image,
  item__counter,
  item__active,
} from "./styles/categoryitem.module.scss"

const CategoryItem = (props) => {
  const { image, slug, name, count, className = [""] } = props
  return (
    <div className={[`${item}`, ...className].join(" ")}>
      <Link to={`/categories/${slug}/`} activeClassName={item__active}>
        <div className={item__image}>
          <Image {...image} size={"300w"} />
          <h6
            className={item__counter}
            dangerouslySetInnerHTML={{ __html: count }}
          />
        </div>
        <h4
          className={item__title}
          dangerouslySetInnerHTML={{ __html: name }}
        />
      </Link>
    </div>
  )
}
export default CategoryItem
