import React, { useRef, useContext } from "react"
import { connect } from "react-redux"
import { Link } from "gatsby"
import { Modal, ProductSummaryItem, AnimatedLink } from "@components/shared"
import { cartActions } from "@stores"
import { CartCtx, useActions, useContextState } from "@components/contexted"
import {
  EmptyCart,
  Toggler,
  CleanCartAction,
  Products,
  Summary,
  ToCheckoutAction,
} from "./common"

import {
  cart,
  cart__clean,
  cart__modal,
  cart__modal__body,
  cart__modal__clean,
  cart__modal__footer,
} from "./cart.module.scss"

const Cart = ({ className }) => {
  const modal = useRef()

  const { removeFromCart, changeProductQty } = useActions(CartCtx, [
    "removeFromCart",
    "changeProductQty",
  ])

  const { items, sum, unit } = useContextState(CartCtx, [
    "items",
    "sum",
    "unit",
  ])

  if (items.length === 0) {
    return <EmptyCart className={className} />
  }

  const translations = {
    "/pl":"Twoje zakupy",
    "/en":"Your shopping"
  }

  let selectedLanguage = "/pl"
  if(typeof window !== "undefined"){
    selectedLanguage = localStorage.getItem("language")
  }
  const currentTitle = translations[selectedLanguage]

  return (
    <div className={cart}>
      <Toggler
        className={cart__clean}
        items={items}
        onClick={() => {
          modal.current.toggle()
        }}
      />

      <Modal title={currentTitle} ref={modal}>
        <div className={cart__modal}>
          <div className={cart__modal__body}>
            <Products
              items={items}
              changeProductQty={changeProductQty}
              removeFromCart={removeFromCart}
            />
          </div>
          <div className={cart__modal__footer}>
            <Summary sum={sum} unit={unit} />
            <ToCheckoutAction />
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default Cart
