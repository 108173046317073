import React from "react"
import PropTypes from "prop-types"

import { Link } from "gatsby"

import { tags as wrapper, tags__item } from "./tags.module.scss"

const Tags = ({ tags, tagsUrl = "/tags" }) => {
  if (tags.length !== 0) {
    return (
      <div className={wrapper}>
        {tags.map(({ name, slug }, k) => {
          return (
            <div className={tags__item} key={k}>
              <Link to={`${tagsUrl}/${slug}/`}>#{name}</Link>
            </div>
          )
        })}
      </div>
    )
  }
  return null
}

Tags.propTypes = {
  tags: PropTypes.array,
  tagsUrl: PropTypes.string.isRequired,
}

export default Tags
