import React from "react"
import { RadioButtons } from "@components/shared"

import { CartCtx, useActions, useContextState } from "@components/contexted"

import { delivery } from "../styles/checkout.module.scss"

const Delivery = ({ methods }) => {
  const { shippings, shipping } = useContextState(CartCtx, [
    "shippings",
    "shipping",
  ])
  console.log("shippings", shippings)
  const { setShipping } = useActions(CartCtx, ["setShipping"])
  return (
    <div className={delivery}>
      <h5>Sposób dostawy </h5>
      <RadioButtons
        items={shippings}
        itemMapper={(el) => ({
          name: "shipping",
          onChange: () => {
            // console.log(el, setShipping)
            setShipping(el)
          },
          value: el,
          checked: el.method_title == shipping.method_title,
          name: `${el.method_title} - ${el.total} PLN`,
        })}
      />
    </div>
  )
}

export default Delivery
