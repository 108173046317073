import React from "react"

import { sideLeft, sideRight } from "./layout.module.scss"

const SideLayout = () => (
  <>
    <div className={sideLeft} />
    <div className={sideRight} />
  </>
)

export default SideLayout
