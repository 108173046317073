import React from "react"
import { TextInput } from "@components/shared"
import { FlexCol, FlexRow } from "@components/layout"
import GitHubLogin from "react-github-login"
import { GitHubApi, redirectUri } from "@api"
import { CustomerCtx, useActions, useContextState } from "@components/contexted"

import {
  customer__form,
  gitlogin,
  git__wrap,
} from "../styles/checkout.module.scss"

const onSuccessGithub = (changeValue) => (data) => {
  GitHubApi.exchangeCode(data.code).then((data) => {
    if (data) {
      changeValue({
        note: data.login,
      })
    }
  })
}

const CustomerForm = (props) => {
  const { selectedLanguage } = props
  const onFailGithub = (data) => {
    console.log("onFailGithub", data)
  }
  const { changeValue } = useActions(CustomerCtx, ["changeValue"])
  const {
    first_name,
    last_name,
    email,
    note,
    address_1,
    city,
    postcode,
    country,
  } = useContextState(CustomerCtx, [
    "first_name",
    "last_name",
    "email",
    "note",
    "address_1",
    "city",
    "postcode",
    "country",
  ])

  const translations = {
    pl:{
      firstHead:"Dane osobowe",
      secondHead:"Dane do wysyłki",
      name:"Imię",
      lastName:"Nazwisko",
      email:"Adres email",
      note:"Informacje dodatkowe",
      street:"Ulica",
      city:"Miasto",
      zip:"Kod pocztowy",
      country:"Kraj"
    },
    en:{
      firstHead:"Personal data",
      secondHead:"Shipping data",
      name:"Name",
      lastName:"Surname",
      email:"E-mail address",
      note:"Additional information",
      street:"Street",
      city:"City",
      zip:"Zip Code",
      country:"Country"
    }
  }
  return (
    <form
      className={customer__form}
      onSubmit={(ev) => {
        ev.preventDefault()
      }}
    >
      <FlexCol justify={"flex-start"}>
        <h5>{translations[selectedLanguage].firstHead}</h5>
        <TextInput
          placeholder={translations[selectedLanguage].name}
          name="firstName"
          value={first_name}
          validate={"text"}
          onChange={(ev) => {
            changeValue({
              first_name: ev.target.value,
            })
          }}
        />
        <TextInput
          placeholder={translations[selectedLanguage].lastName}
          name="lastName"
          value={last_name}
          onChange={(ev) => {
            changeValue({
              last_name: ev.target.value,
            })
          }}
        />
        <TextInput
          type={"email"}
          name="email"
          validate={"email"}
          placeholder={translations[selectedLanguage].email}
          value={email}
          onChange={(ev) => {
            changeValue({
              email: ev.target.value,
            })
          }}
        />
        <div className={git__wrap}>
          <TextInput
            placeholder={translations[selectedLanguage].note}
            name="customerNote"
            value={note}
            onChange={(ev) => {
              changeValue({
                note: ev.target.value,
              })
            }}
          />
        </div>
        <h5>{translations[selectedLanguage].secondHead}</h5>
        <TextInput
          placeholder={translations[selectedLanguage].street}
          name="address"
          value={address_1}
          onChange={(ev) => {
            changeValue({
              address_1: ev.target.value,
            })
          }}
        />
        <TextInput
          placeholder={translations[selectedLanguage].city}
          name="city"
          value={city}
          onChange={(ev) => {
            changeValue({
              city: ev.target.value,
            })
          }}
        />
        <TextInput
          placeholder={translations[selectedLanguage].zip}
          value={postcode}
          name="zipCode"
          onChange={(ev) => {
            changeValue({
              postcode: ev.target.value,
            })
          }}
        />
        <TextInput
          placeholder={translations[selectedLanguage].country}
          name="country"
          value={country}
          onChange={(ev) => {
            changeValue({
              country: ev.target.value,
            })
          }}
        />
      </FlexCol>
    </form>
  )
}

export default CustomerForm
