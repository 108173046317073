import React from "react"
import { ProductSummaryItem } from "@components/shared"

import { summary__wrapper } from "../styles/checkout.module.scss"

const ProductsSummary = ({
  cart,
  changeProductQty,
  removeFromCart,
  presentation = false,
}) => {
  const { items, shipping, sum, coupon } = cart
  return (
    <div className={summary__wrapper}>
      {items.map((el, k) => (
        <ProductSummaryItem
          {...el}
          key={k}
          changeProductQty={changeProductQty}
          removeFromCart={removeFromCart}
        />
      ))}
    </div>
  )
}

export default ProductsSummary
