import React from "react"
import { product__description } from "../styles/product.module.scss"

const Description = ({ description }) => {
  return (
    <div
      className={product__description}
      dangerouslySetInnerHTML={{ __html: description }}
    ></div>
  )
}

export default Description
