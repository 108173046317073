import React, { useRef, useContext } from "react"

import {
  CustomerCtx,
  useActions,
  useContextState,
  CartCtx,
  AppCtx,
} from "@components/contexted"
import {
  CustomerForm,
  Delivery,
  DiscountAndContinue,
  ErrorMessage,
  createOrder,
} from ".."

const OrderAndDeliveryStep = (props) => {
  const { pageContext, selectedLanguage, discountTranslations } = props
  console.log(selectedLanguage)
  const customer = useContext(CustomerCtx)
  const cart = useContext(CartCtx)

  const { loading, loaded } = useActions(AppCtx, ["loading", "loaded"])
  const { setOrderFromApi, onOrderApiError } = useActions(CartCtx, [
    "setOrderFromApi",
    "onOrderApiError",
  ])

  const translations = {
    pl:{
      title:"2. Wypełnij dane",
      label:"Potwierdź zamówienie",
      regulatonsText:"Dokonując zakupów oświadczasz, że zapoznałeś się i akceptujesz nasz",
      regulationsLabel:"regulamin"
    },
    en:{
      title:"2. Fill in the data",
      label:"Confirm order",
      regulatonsText:"By making a purchase, you declare that you have read and accept our",
      regulationsLabel:"regulations",
    }
  }

  return (
    <div title={translations[selectedLanguage].title}>
      <CustomerForm selectedLanguage={selectedLanguage}/>
      <Delivery />
      <ErrorMessage cart={cart.state} />
      <DiscountAndContinue
        selectedLanguage={selectedLanguage}
        translations={discountTranslations}
        nextStep={() => {
          createOrder({
            cart: cart.state,
            customer: customer.state,
            initLoading: loading,
          })
            .then((data) => {
              loaded()
              setOrderFromApi(data)
            })
            .catch(({ data }) => {
              loaded()
              onOrderApiError(data)
            })
        }}
        label={translations[selectedLanguage].label}
      />
      <div className="row justify-content-center">
        {translations[selectedLanguage].regulatonsText}
        &nbsp;
        <a href="/regulaminy"> {translations[selectedLanguage].regulationsLabel}</a>.
      </div>
    </div>
  )
}

export default OrderAndDeliveryStep
